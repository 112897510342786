import React from 'react';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Fluid from 'layouts/Fluid';
import Container from 'components/Container';
// import ResizableFrame from 'components/ResizableFrame';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Footer } from 'layouts/Main/components';
import FormData from 'form-data';
import getCookie from 'views/NotFoundCover/utils/getCookie';
import axios from 'axios';

const NotFound = () => {
  let isDisabled = true;
  var data = new FormData();

  const window_ = globalThis.window;
  const searchParam = window_ && window_.location && window_.location.search;
  const urlSearchParams = new URLSearchParams(searchParam);
  const params = Object.fromEntries(urlSearchParams.entries());

//   let tokenData = getCookie('tokenData');
//   tokenData = JSON.parse(tokenData);
//   const access_token = tokenData && tokenData.access_token;



// if(!access_token) {
//   alert('your are not authorized and redirecting to Home page.')
//   window.location.pathname = '/';
// }
  // let tokenData = getCookie('tokenData');
  // tokenData = JSON.parse(tokenData);
  // const access_token = tokenData && tokenData.access_token;

var data = new FormData();
// studentData.phone
  data.append('fullName', '');
  data.append('aadhar', '');
  data.append('date1', '');
  data.append('birth', '');
  data.append('photo', '');
  data.append('siblings', '');
  data.append('dateOfBirth', '');
  data.append('class1', '');
  data.append('birthPlace', '');
  data.append('nationality', '');
  data.append('religion', '');
  data.append('motherTongue', '');
  data.append('gender', '');
  data.append('application_id', '');
  data.append('fatherName', '');
  data.append('motherName', '');
  data.append('phone', '');
  data.append('phone1', '');
  data.append('email', '');
  data.append('email1', '');
  data.append('fatherLang', '');
  data.append('motherLang', '');
  data.append('resadd', '');
  data.append('resadd1', '');
  data.append('area', '');
  data.append('area1', '');
  data.append('dist', '');
  data.append('dist1', '');
  data.append('state', '');
  data.append('state1', '');
  data.append('country', '');
  data.append('country1', '');
  data.append('pin', '');
  data.append('pin1', '');
  data.append('offnum', '');
  data.append('offnum1', '');
  data.append('resnum', '');
  data.append('resnum1', '');
  data.append('gross', '');
  data.append('gross1', '');
  data.append('working', '');
  data.append('working1', '');
  data.append('transactionId', '');
  data.append('orderID', '');
  data.append('transactionSignature', '');
  data.append('status', 'DRAFT');

  var config = {
    method: 'post',
    url: 'http://204.236.192.144:49159/api/account/create-application',
    // headers: {
    //   Authorization:
    //     `Bearer ${access_token}` ,
    // },
    data: data,
  };

  const onSubmit = () => {
       axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  
  
    const formik = useFormik({
      initialValues: { agree: false },
      onSubmit: onSubmit,
    });
  
    if (formik.values.agree === true) {
      isDisabled = false;
    }
 
  return (
    <>
      <div style={{ height: 40, backgroundColor: '#377dff', padding: '10px' }}>
        <Typography
          style={{ float: 'left' }}
          variant={'subtitle2'}
          color={'white'}
        >
          NPS YPR
        </Typography>
        <a style={{ float: 'right' }} href="/online-registration-email-login">
          <img
            height={20}
            width={20}
            color={'white'}
            src="https://cdn-icons-png.flaticon.com/512/25/25376.png"
          />
        </a>
      </div>

      <Box bgcolor={'alternate.main'}>
        <br />
        <br />
        <Container maxWidth={0.8} paddingY={'0 !important'}>
          <Box>
            <Typography variant={'h4'} fontWeight={700} align={'center'}>
            GUIDELINES AND CONTACT DETAILS
            </Typography>
            <br />
            <br />

            
            <Typography variant={'h6'} fontWeight={700} align={'center'}>
            ADMISSIONS
            </Typography>
            <br></br>
            <Typography  fontWeight={250} align={'justify'}>

            Welcome to National Public School, Yeshwanthpur, an institution which prepares and motivates the students for a rapidly changing world by instilling in them critical thinking skills, a global perspective and respect for core values of honesty, loyalty, perseverance and compassion. <br/><br />
           
Admission to National Public School, Yeshwanthpur, is open to all children from Pre-K, Kindergarten and Classes 1 to 9 & 11 and to I-5 Academy for Montessori.
<br /><br/>
REGISTRATION FOR ADMISSION
Registration form is available online on our website from 10/10/2022. Click Here
<br /><br />
SUBMIT THE COMPLETED DOWNLOADED FORM ALONG WITH THE FOLLOWING DOCU1MENTS ATTESTED BY THE PARENTS DURING INTERACTION OR ENTRANCE EXAM: <br></br><br></br>
1. Passport size photo of the student <br></br><br></br>
2. Student’s Birth Certificate.<br /><br />
3. Immunisation Card (applicable upto Class 1)<br /><br />
4. Covid Vaccination Certificate<br /><br />
5. Student’s Aadhar Card.<br /><br />
6. Parents’ Aadhar Card.<br /><br />
7. Registration Fee - ₹ 500/- (Online Payment)<br /><br />
We appreciate the interest evinced by the parents seeking admission to National Public School Yeshwanthpur. We would like to inform that we have very limited seats available for admission to all the classes from Classes 1 to 9 & 11. The seats will be available in the open category according to the priority list given below:<br /><br />
1. The first priority is for siblings.<br /><br />
2. The second priority is for children of our teachers and other staff members.<br /><br />
3. Priority for admission is also listed for Alumni of National Public School.<br /><br />
4. Subsequent seats will be open to others.<br /><br />
5. Applications for admission to Mont II, Mont III, KG II and classes 2-7 are based on vacancies.<br /><br />
6. Class 10 & Class 12 admissions are based on Interstate transfer.<br /><br />
To have an effective teaching & learning process, we maintain a limited class strength. Considering the limited number of seats available, it is expected that those who seek admission will realize and understand the constraints the institution faces.<br />
Admission to classes 1 to 9 & 11 are finalized based on the performance in the written admission test conducted in the month of January 2022.<br /><br />
</Typography>

              <Typography variant={'h4'}>
             <br/> Address for Communication :
              </Typography>
              <Typography  fontWeight={250} align={'justify'}>
              NATIONAL PUBLIC SCHOOL
              <br />
              No. 9/1, Pipeline Road, Raghavendra Layout,
              <br />
              Yeshwanthpur,
              <br />
              Bengaluru – 560 022
              <br />
              Phone: 091-080-23571220 / 091-6364071122 &nbsp;&nbsp; Email:
              admissions@npsypr.edu.in
              <br />
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  id={'agree'}
                  name={'agree'}
                  color="primary"
                  defaultChecked={formik.values.agree}
                  onChange={formik.handleChange}
                />
              }
              label="I Agree"
            />
            <Box>
              <Button
                size={'large'}
                align={'center'}
                variant={'contained'}
                type={'submit'}
                // marginTop={{ xs: 8, sm: 9 }}
                // marginLeft={{ sm: 60 }}
                href="/password-reset-simple"
              >
                Go to Home
              </Button>
              &nbsp;&nbsp;
              <Button
                size={'large'}
                align={'center'}
                variant={'contained'}
                type={'submit'}
                // marginTop={{ xs: 8, sm: 9 }}
                // marginLeft={{ sm: 4 }}
                disabled={isDisabled}
                onClick={onSubmit}
                href={`/not-found-cover?section=${params.section}`}
              >
                Continue
              </Button>
            </Box>
          </Box>
          <br />
          <br />
        </Container>
      </Box>
      <Container maxWidth={0.9} marginTop={-6}>
        <Footer />
      </Container>
    </>
  );
  };


export default NotFound;